import { Component } from "react";

class Developer extends Component {
  render() {
    return (
      <h1>
        Dev {this.props.name} <br />
        idade: {this.props.age} <br />
        País {this.props.country}.
      </h1>
    );
  }
}
export default Developer;
