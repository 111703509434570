import "./App.css";
import Developer from "./Developer";

function App() {
  return (
    <div className="App">
      <Developer name="Julia" age="30" country="Brasil"></Developer>
      <Developer name="Maria" age="35" country="Argentina"></Developer>
      <Developer name="João" age="19" country="Chile"></Developer>
    </div>
  );
}

export default App;
